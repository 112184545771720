import React, { useState } from "react"
import classNames from "classnames"
import { ReactSVG } from "react-svg"

// ---------------------------------------------------------

import {
  fullwidth_section,
  fullwidth_open,
  fullwidth_closed,
  close_icon_container,
  close_icon,
  video_help_text
} from "./styles.module.scss"

// ---------------------------------------------------------

const FullWidthVideo = () => {
  const [isSectionOpen, setIsSectionOpen] = useState(true)

  const handleClick = () => {
    const vid = document.getElementById("video")
    isSectionOpen ? vid.pause() : null
    setIsSectionOpen(!isSectionOpen)
  }

  return (
    <section
      className={classNames(fullwidth_section, isSectionOpen ? fullwidth_open : fullwidth_closed)}
    >
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <video muted autoPlay controls playsInline controlsList="nodownload" id="video">
        <source
          src="https://videos.ctfassets.net/5vqt5w81x2im/6jJDXbVn1hDq4lFxyAgGol/74ec54c1a4608433e9a43193b53142dc/Amperity_is_The_Platform.mp4"
          type="video/mp4"
        />
        <p>Amperity is the platform for customer data.</p>
      </video>
      {!isSectionOpen ? (
        <button
          onClick={() => {
            handleClick()
          }}
          onKeyDown={() => {
            handleClick()
          }}
        >
          <span className={video_help_text}>Better data, better results</span>
        </button>
      ) : null}
      <div
        role="button"
        tabIndex={0}
        className={close_icon_container}
        onClick={() => {
          handleClick()
        }}
        onKeyDown={() => {
          handleClick()
        }}
      >
        <ReactSVG className={close_icon} src={`/icons/x-mark.svg`} alt="Close modal" />
      </div>
    </section>
  )
}

export default FullWidthVideo
